"use client";

import { useRef, useState } from "react";
import { TriangleRightIcon } from "@radix-ui/react-icons";
import { cn } from "~/utils/cn";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/v2/Collapsible";

import { CollapsibleListViewProps } from "./collapsible-list-view.types";
import { CandidateListView } from "../candidate-list-view";
import { CandidateListViewTitle } from "~/scalis-components/pipeline/list-view/candidate-list-view-title";
import { ListStatusPlaceholder } from "~/components/v2/list-status-placeholder";

export const CollapsibleListView: React.FC<CollapsibleListViewProps> = ({
  listView,
  title,
  squareColor,
  handleClickTitle,
  id,
  open = false,
  candidates,
  isLoading,
  showSeeAllCandidates,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="justify-star flex">
      <Collapsible className="w-full" open={isOpen} onOpenChange={setIsOpen}>
        <div className="flex">
          <CollapsibleTrigger className="group" asChild>
            <div className="flex h-8 cursor-pointer items-center" role="button">
              <TriangleRightIcon className="h-5 w-5 rounded-md text-icons-low-contrast hover:bg-primary-background active:bg-primary-background-hover active:text-typography-color group-data-[state=open]:rotate-90" />
            </div>
          </CollapsibleTrigger>
          <CandidateListViewTitle
            squareColor={squareColor}
            title={title}
            action={handleClickTitle}
            stageId={Number(id)}
          />
        </div>

        {isLoading && (
          <div className="pb-4">
            <div className="rounded-xl border border-solid border-borders-borders p-8">
              <ListStatusPlaceholder name="candidates" isLoading={true} />
            </div>
          </div>
        )}

        {!isLoading && (
          <>
            {!!candidates.length ? (
              <CollapsibleContent
                className={cn(
                  "list-view-container mt-5 h-[var(--radix-collapsible-content-height)] overflow-hidden bg-white hover:overflow-x-auto",
                )}
                ref={containerRef}
              >
                <div className="pb-4">
                  <CandidateListView
                    {...listView}
                    showSeeAllCandidates={showSeeAllCandidates}
                    rows={candidates}
                    id={id}
                    ref={containerRef}
                  />
                </div>
              </CollapsibleContent>
            ) : (
              <CollapsibleContent className={cn("mt-5 h-60 overflow-hidden")}>
                <div className="pb-4">
                  <div className="rounded-xl border border-solid border-borders-borders p-8">
                    <ListStatusPlaceholder name="candidates" isEmpty={true} />
                  </div>
                </div>
              </CollapsibleContent>
            )}
          </>
        )}
      </Collapsible>
    </div>
  );
};
