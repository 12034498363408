import { useRef } from "react";
import { cn } from "~/utils/cn";
import { useCandidateApplicationData } from "../hooks/use-candidate-application-data";
import { CandidateListView } from "~/scalis-components/pipeline/list-view/candidate-list-view";
import { ListStatusPlaceholder } from "~/components/v2/list-status-placeholder";
import { CandidateListViewTitle } from "../candidate-list-view-title";
import { CandidateListViewViewerProps } from "./candidate-list-view-viewer.types";

export const CandidateListViewViewer: React.FC<
  CandidateListViewViewerProps
> = ({
  squareColor,
  title,
  listView,
  className,
  id,
  showActionBar,
  excludeColumns = [],
  candidates,
  isLoading,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="h-full px-4 py-5">
      <CandidateListViewTitle
        stageId={Number(id)}
        squareColor={squareColor}
        title={title}
      />

      {isLoading && (
        <div className="pb-4">
          <div className="rounded-xl border border-solid border-borders-borders p-8">
            <ListStatusPlaceholder name="candidates" isLoading={true} />
          </div>
        </div>
      )}

      {!isLoading && (
        <div
          className={cn(
            "list-view-container mt-5 h-[calc(100%-40px)] overflow-hidden bg-white hover:overflow-x-auto hover:overflow-y-auto",
            className,
          )}
          ref={containerRef}
        >
          <div className="pb-4">
            {!!candidates.length ? (
              <CandidateListView
                {...listView}
                rows={candidates}
                id={id}
                ref={containerRef}
                excludeColumns={excludeColumns}
                showActionBar={showActionBar}
              />
            ) : (
              <div className="rounded-xl border border-solid border-borders-borders p-8">
                <ListStatusPlaceholder name="candidates" isEmpty={true} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
