import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "~/components/v2/DropdownMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { ActionsMenuProps, AlignMenu } from "./actions-menu.types";
import { ActionsMenuItems } from "../../list-view.types";
import React from "react";
import { Icon } from "~/scalis-components/core/icon";
import { cn } from "~/utils/cn";

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  actionsMenuItems,
  alignMenu = AlignMenu.start,
  tooltipText = "",
  row,
  isHeader = false,
}) => {
  const GroupMenuItems = ({ groupItem }: { groupItem: ActionsMenuItems[] }) => {
    return (
      <DropdownMenuGroup>
        {groupItem.map(item => (
          <DropdownMenuItem
            key={item.label}
            className="group flex h-9 gap-2 p-2 hover:cursor-pointer hover:bg-button-subtle-hover"
            onClick={event => {
              !!item.onClick && item.onClick(row?.original ?? event);
            }}
          >
            {item.icon && (
              <div className="flex size-6 items-center justify-center">
                <i className={cn("text-neutral-primary", item.icon)} />
              </div>
            )}
            <p className="text-sm font-normal text-neutral-primary">
              {item.label}
            </p>
          </DropdownMenuItem>
        ))}
      </DropdownMenuGroup>
    );
  };

  return (
    <div className="flex w-full items-center justify-center">
      <DropdownMenu modal={false}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger className="focus:outline-none" asChild>
                <Icon
                  icon="fa-solid fa-ellipsis text-icon-neutral-20"
                  className={cn(
                    isHeader &&
                      "hover:bg-surface-brand-hover active:bg-button-brand-primary-light-pressed",
                  )}
                />
              </DropdownMenuTrigger>
            </TooltipTrigger>
            {!!tooltipText && (
              <TooltipContent>
                <p className="z-50">{tooltipText}</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>

        <DropdownMenuContent
          align={alignMenu}
          className="w-40 rounded-xl bg-white p-2 text-neutral-primary shadow-sm"
        >
          {actionsMenuItems.map((groupItem, index) => {
            return (
              <React.Fragment key={`action-group-${index}`}>
                {index > 0 && (
                  <DropdownMenuSeparator className="mx-3 bg-borders-borders" />
                )}
                <GroupMenuItems groupItem={groupItem} />
              </React.Fragment>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
