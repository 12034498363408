import { PropsWithChildren } from "react";
import { GetCandidateApplicationQueryVariables } from "~/bff/graphql/generated/graphql";
import { JobListingFiltersData } from "~/src/app/company/(dashboard)/pipeline/hooks/use-get-job-listing-filters-data";

export type CandidateApplicationFiltersContextProps = {
  filters: GetCandidateApplicationQueryVariables;
  filtersData: JobListingFiltersData;
};

export type CandidateApplicationFiltersContextProviderProps =
  PropsWithChildren<{
    jobPublicListingId?: string;
    filterTabs: Array<{ label: string; value: string }>;
  }>;

export type CandidateApplicationFiltersContextProviderInternalProps = Omit<
  CandidateApplicationFiltersContextProviderProps,
  "filterTabs"
>;

export enum CandidateApplicationFilterTab {
  ACTIVE_CANDIDATES = "active-candidates",
  SCALIS_CANDIDATES = "scalis-candidates",
  UPLOADED = "uploaded",
  INVITED_TO_APPLY = "invited-to-apply",
  REJECTED = "rejected",
}
