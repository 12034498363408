import { Header } from "@tanstack/react-table";
import React from "react";
import { Icon } from "~/scalis-components/core/icon";

type SortIconProps = {
  header: Header<unknown, unknown>;
  sorting: { id: string; desc: boolean }[];
};

export const SortColumnIcon: React.FC<SortIconProps> = ({
  header,
  sorting,
}) => {
  const getIcon = () => {
    if (sorting[0]?.id === header.column.id && !sorting[0]?.desc) {
      return "fa-solid fa-sort-up";
    }

    if (sorting[0]?.id === header.column.id && sorting[0]?.desc) {
      return "fa-solid fa-sort-down";
    }

    return "fa-solid fa-sort";
  };

  const icon = getIcon();

  return (
    <Icon
      icon={icon}
      onClick={header.column.getToggleSortingHandler() as unknown as any}
      role="button"
      className="relative hover:bg-surface-brand-hover active:bg-button-brand-primary-light-pressed"
    />
  );
};
