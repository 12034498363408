"use client";

import React, { useState } from "react";
import { FieldValues, useFormContext, useFormState } from "react-hook-form";
import {
  Button,
  ButtonSize,
  ButtonStandaloneKind,
  Drawer,
} from "~/scalis-components/core";
import { FilterForm } from "./components/filter-form";
import { setAllFormValues } from "./filters-drawer.utils";
import { FiltersDrawerProps } from "./filters-drawer.types";
// TODO: We probably need to move this
import { FormValues } from "@scalis/components/pipeline/list-view/candidate-filters/candidate-filters.schema";

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  open,
  onOpenChange,
  onApplyFilters,
  filterOptions,
}) => {
  const { reset, getValues, setValue } = useFormContext();
  const { dirtyFields, isSubmitting } = useFormState();
  const [startFormValues, setStartFormValues] = useState({});

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);

    if (open) {
      setStartFormValues(getValues());
      return;
    }

    setAllFormValues(startFormValues, setValue);
  };

  const activeFiltersNumber = Object.keys(dirtyFields).length;

  const onFormSubmit = (data: FieldValues) => {
    setStartFormValues(data);
    onApplyFilters(
      dirtyFields as {
        [K in keyof FormValues]: boolean;
      },
    );
    onOpenChange(false);
  };

  return (
    <Drawer
      isOpen={open}
      onOpenChange={handleOpenChange}
      title={`Advanced Filters${activeFiltersNumber > 0 ? ` (${activeFiltersNumber})` : ""}`}
      contentClassName="p-0"
      actions={
        <Button
          isStandalone
          kind={ButtonStandaloneKind.brand}
          size={ButtonSize.small}
          onClick={() => reset()}
        >
          Clear All
        </Button>
      }
      endAlignedFooterActions
      successButtonProps={{
        label: "Save",
        formId: "advanced-filters-form",
        disabled: isSubmitting,
        type: "submit",
      }}
    >
      <div className="overflow-y-auto">
        <FilterForm onFormSubmit={onFormSubmit} filterOptions={filterOptions} />
      </div>
    </Drawer>
  );
};
