import { useRouter } from "next/navigation";
import { CollapsibleListView } from "../list-view/collapsible-list-view";
import { mapMilestonesToCollapsableList } from "./milestones-list-utils";
import { MilestonesListProps } from "./milestones-list.types";

export const MilestonesList: React.FC<MilestonesListProps> = ({ ...props }) => {
  const router = useRouter();

  const collapsibleList = mapMilestonesToCollapsableList(props);

  return (
    <div className="flex w-full flex-col gap-4 py-5">
      {collapsibleList.map(item => (
        <CollapsibleListView
          key={item.id}
          {...item}
          listView={{
            ...item.listView,
            allCandidatesCallback: () => router.push(item.viewDetailsRoute),
          }}
          id={item.id}
          handleClickTitle={() => router.push(item.viewDetailsRoute)}
          open={true}
        />
      ))}
    </div>
  );
};
