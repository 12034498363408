"use client";

import React, { useMemo } from "react";
import { useParams } from "next/navigation";
import {
  GetScreenStageCollaboratorsDocument,
  GetScreenStageCollaboratorsQuery,
  useDeleteStageCollaboratorMutation,
  useGetScreenStageCollaboratorsQuery,
  useGetStageCompanyUsersQuery,
  useGetTemplateStageModalDataQuery,
  useUpsertScreenStageCollaboratorMutation,
} from "~/bff/graphql/generated/graphql";
import { useSearchParamsManager } from "../hooks/use-search-params-manager";
import { milestoneNameMap } from "~/utils/enum-mappers";
import { Routes } from "~/src/app/_constants/routes";
import {
  ComposableDialog,
  Divider,
  NotificationKind,
  useNotification,
} from "~/scalis-components/core";
import { ButtonKind } from "~/scalis-components/core/button";
import { ComboBox } from "~/scalis-components/core/input/combobox";
import { MemberListItem } from "~/scalis-components/team/member-list-item";
import { StageCollaboratorsDialogNoResults } from "./components/stage-collaborators-dialog-no-results";
import {
  DIALOG_QUERY_PARAM,
  DIALOG_TITLE,
  DIALOG_INPUT_LABEL,
  DIALOG_INPUT_PLACEHOLDER,
  DIALOG_SECTION_TITLE,
  DIALOG_COPY_BUTTON_TITLE,
  DIALOG_COPY_SUCCESS_TITLE,
  DIALOG_COPY_SUCCESS_DESCRIPTION,
  DIALOG_MEMBER_TERM,
} from "./stage-collaborators-dialog.constants";
import { StageCollaboratorTeamMember } from "./stage-collaborators-dialog.types";

export const StageCollaboratorsDialog = () => {
  const params = useParams<{ jobId: string }>();
  const { searchParams, removeQueryParam } = useSearchParamsManager();
  const { notification } = useNotification();

  const isOpen = !!searchParams?.has(DIALOG_QUERY_PARAM);
  const stageId = Number(searchParams?.get(DIALOG_QUERY_PARAM));

  const { data: modalData } = useGetTemplateStageModalDataQuery({
    variables: { id: stageId },
    skip: !isOpen,
  });

  const { data: stageCollaboratorsData } = useGetScreenStageCollaboratorsQuery({
    variables: { id: stageId },
    skip: !isOpen,
  });

  const { data: companyUsersData } = useGetStageCompanyUsersQuery({
    skip: !isOpen,
  });

  const [upsertScreenStageCollaborator] =
    useUpsertScreenStageCollaboratorMutation();

  const [deleteStageCollaborator] = useDeleteStageCollaboratorMutation();

  const stage = modalData?.GetTemplateStage;
  const templatePipeline = modalData?.GetTemplateStage?.TemplatePipeline;
  const jobPipeline = templatePipeline?.JobPipeline?.[0];
  const milestone = modalData?.GetTemplateStage?.TemplateMilestone;
  const milestoneName = milestone?.name ? milestoneNameMap[milestone.name] : "";
  const dialogDescription = `${milestoneName} - ${stage?.name ?? ""}`;

  const members = useMemo<StageCollaboratorTeamMember[]>(() => {
    if (!stageCollaboratorsData?.GetTemplateStage?.StageCollaborator) return [];

    return stageCollaboratorsData?.GetTemplateStage?.StageCollaborator.map(
      stageCollaborator => {
        const companyUser = stageCollaborator.CompanyUser;
        const userInvitation = companyUser?.UserInvitation;
        const scalisUser = userInvitation?.ScalisUser;

        return {
          id: stageCollaborator?.id ?? "",
          name: `${scalisUser?.firstName ?? ""} ${scalisUser?.lastName ?? ""}`,
          email: userInvitation?.email ?? "",
          avatarUrl: companyUser?.profileImage ?? "",
          extraData: {
            firstName: scalisUser?.firstName ?? "",
            lastName: scalisUser?.lastName ?? "",
            userInvitationId: Number(userInvitation?.id),
            scalisUserId: Number(scalisUser?.id),
            companyUserId: Number(companyUser?.id),
          },
        };
      },
    );
  }, [stageCollaboratorsData?.GetTemplateStage?.StageCollaborator]);

  const availableMembers = useMemo(() => {
    if (!companyUsersData?.GetCompanyUsers) return [];

    const availableUsers = companyUsersData.GetCompanyUsers.filter(
      companyUser =>
        !members.find(
          member => member.extraData.companyUserId === Number(companyUser.id),
        ),
    );

    return availableUsers.map(companyUser => {
      const userInvitation = companyUser?.UserInvitation;
      const scalisUser = userInvitation?.ScalisUser;

      return {
        id: companyUser.id,
        name: `${scalisUser?.firstName ?? ""} ${scalisUser?.lastName ?? ""}`,
        email: userInvitation?.email ?? "",
        avatarUrl: companyUser?.profileImage ?? "",
        extraData: {
          firstName: scalisUser?.firstName ?? "",
          lastName: scalisUser?.lastName ?? "",
          userInvitationId: Number(userInvitation?.id),
          scalisUserId: Number(scalisUser?.id),
          companyUserId: Number(companyUser.id),
        },
      };
    });
  }, [members, companyUsersData]);

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      removeQueryParam(DIALOG_QUERY_PARAM);
    }
  };

  const handleFilter = (query: string) => {
    return availableMembers.filter(member => {
      const lowercaseQuery = query.toLowerCase();
      const matchingFields = [member.name, member.email];

      return matchingFields.some(field =>
        field.toLowerCase().includes(lowercaseQuery),
      );
    });
  };

  const onCopyButtonClick = async () => {
    const stageRoute = Routes.job_listing.stage({
      jobPublicListingId: params?.jobId ?? "",
      milestoneId: Number(milestone?.id),
      stageId,
    });

    await navigator.clipboard.writeText(`${location.origin}${stageRoute}`);

    notification({
      title: DIALOG_COPY_SUCCESS_TITLE,
      description: DIALOG_COPY_SUCCESS_DESCRIPTION,
      kind: NotificationKind.success,
    });
  };

  const onAddMember = async (member: StageCollaboratorTeamMember) => {
    const stageCollaboratorValues = {
      companyUserId: Number(member.id),
      requisitionId: Number(jobPipeline?.jobRequisitionId),
      templateStageId: stageId,
      roleId: 1, // TODO: Get this from selected value
    };

    await upsertScreenStageCollaborator({
      variables: {
        input: {
          ...stageCollaboratorValues,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        UpsertStageCollaborator: {
          __typename: "StageCollaborator",
          ...stageCollaboratorValues,
          id: "temp-id",
          CompanyUser: {
            __typename: "CompanyUser",
            id: member.id,
            profileImage: member.avatarUrl,
            UserInvitation: {
              __typename: "UserInvitation",
              id: member.extraData.userInvitationId,
              email: member.email,
              ScalisUser: {
                __typename: "ScalisUser",
                id: member.extraData.scalisUserId,
                firstName: member.extraData.firstName,
                lastName: member.extraData.lastName,
              },
            },
          },
        },
      },
      update: (cache, { data }) => {
        const upsertStageCollaborator = data?.UpsertStageCollaborator;

        const cacheData = cache.readQuery<GetScreenStageCollaboratorsQuery>({
          query: GetScreenStageCollaboratorsDocument,
          variables: { id: stageId },
        });

        if (cacheData) {
          const cachedStageCollaborators =
            cacheData.GetTemplateStage?.StageCollaborator?.filter(
              ({ id }) => id !== upsertStageCollaborator?.id,
            ) ?? [];

          cache.writeQuery({
            query: GetScreenStageCollaboratorsDocument,
            variables: { id: stageId },
            data: {
              ...cacheData,
              GetTemplateStage: {
                ...cacheData.GetTemplateStage,
                StageCollaborator: [
                  ...cachedStageCollaborators,
                  upsertStageCollaborator,
                ],
              },
            },
          });
        }
      },
    });
  };

  const onRemoveMember = async (id: string) => {
    await deleteStageCollaborator({
      variables: {
        input: { id: Number(id) },
      },
      optimisticResponse: {
        __typename: "Mutation",
        DeleteStageCollaborator: {
          __typename: "StageCollaborator",
          id,
        },
      },
      update: (cache, { data }) => {
        const deleteStageCollaborator = data?.DeleteStageCollaborator;

        const cacheData = cache.readQuery<GetScreenStageCollaboratorsQuery>({
          query: GetScreenStageCollaboratorsDocument,
          variables: { id: stageId },
        });

        if (cacheData) {
          cache.writeQuery({
            query: GetScreenStageCollaboratorsDocument,
            variables: { id: stageId },
            data: {
              ...cacheData,
              GetTemplateStage: {
                ...cacheData.GetTemplateStage,
                StageCollaborator:
                  cacheData.GetTemplateStage?.StageCollaborator?.filter(
                    ({ id }) => id !== deleteStageCollaborator?.id,
                  ),
              },
            },
          });
        }
      },
    });
  };

  return (
    <ComposableDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      hideCancelButton={true}
      title={DIALOG_TITLE}
      description={dialogDescription}
      className="lg:max-w-xl"
      contentClassName="p-0"
      successButtonProps={{
        label: DIALOG_COPY_BUTTON_TITLE,
        kind: ButtonKind.secondary,
        startAdornment: "fa-regular fa-link",
        onClick: onCopyButtonClick,
      }}
    >
      <div className="p-4">
        <ComboBox
          handleFilter={handleFilter}
          list={availableMembers}
          noResults={<StageCollaboratorsDialogNoResults />}
          onSelect={onAddMember}
          renderItem={(item: StageCollaboratorTeamMember) => (
            <MemberListItem member={item} />
          )}
          term={DIALOG_MEMBER_TERM}
          label={DIALOG_INPUT_LABEL}
          placeholder={DIALOG_INPUT_PLACEHOLDER}
        />
      </div>

      {!!members.length && (
        <>
          <Divider />

          <div className="flex flex-col gap-4 p-4">
            <span className="text-sm/6 tracking-tighter text-neutral-primary">
              {DIALOG_SECTION_TITLE}
            </span>

            <div className="flex flex-col gap-4">
              {members.map(member => (
                <MemberListItem
                  key={member.id}
                  member={member}
                  onRemove={onRemoveMember}
                  term={DIALOG_MEMBER_TERM}
                  isSelected
                />
              ))}
            </div>
          </div>
        </>
      )}
    </ComposableDialog>
  );
};
