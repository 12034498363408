import { useGetJobListingFiltersDataQuery } from "~/bff/graphql/generated/graphql";
import * as filtersConst from "~/scalis-components/team/contexts/candidate-application-filters-context/candidate-application-filters-context.constants";
import {
  JobListingFiltersData,
  UseJobListingFiltersDataProps,
} from "./use-get-job-listing-filters-data.types";
import { mapToOptions } from "./use-get-job-listing-filters-data.utils";

export const useJobListingFiltersData = ({
  jobPublicListingId,
}: UseJobListingFiltersDataProps) => {
  const {
    data: jobListingFiltersData,
    loading: isLoading,
    error,
  } = useGetJobListingFiltersDataQuery({
    variables: { publicListingId: jobPublicListingId },
    skip: !jobPublicListingId,
  });

  const filtersData =
    jobListingFiltersData?.GetCandidateApplicationFilterContent;

  const data: JobListingFiltersData = {
    [filtersConst.FIELD_NAME_CANDIDATE_PROFILE_ID]: mapToOptions(
      filtersData?.candidateNames,
    ),
    [filtersConst.FIELD_NAME_COMPANY]: mapToOptions(filtersData?.companies),
    [filtersConst.FIELD_NAME_DEGREE]: mapToOptions(filtersData?.degrees),
    [filtersConst.FIELD_NAME_EMAIL]: mapToOptions(filtersData?.emails),
    [filtersConst.FIELD_NAME_JOB_TITLE]: mapToOptions(filtersData?.jobTitles),
    [filtersConst.FIELD_NAME_MAJOR]: mapToOptions(filtersData?.majors),
    [filtersConst.FIELD_NAME_SCHOOL]: mapToOptions(filtersData?.schools),
    [filtersConst.FIELD_NAME_SKILLS]: mapToOptions(filtersData?.skills, {
      labelAsValue: true,
    }),
  };

  return { data, isLoading, error };
};
