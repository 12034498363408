"use client";

import { Controller, useFormContext } from "react-hook-form";
import { PopUpFilter } from "../../pop-up-filter";
import { DateFilterProps } from "./date-filter.types";
import { DatePicker } from "~/scalis-components/core/date-picker";
import { DatepickerMode } from "~/scalis-components/core/date-picker/date-picker.types";

export const DateFilter: React.FC<DateFilterProps> = ({
  label,
  name,
  mode = "range",
  onApplyFilters,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <PopUpFilter
          name={name}
          label={label}
          active={!!value}
          onApplyFilters={onApplyFilters}
        >
          <DatePicker
            ariaLabel={name}
            date={value}
            onSelect={onChange}
            mode={
              mode === "range" ? DatepickerMode.Range : DatepickerMode.Single
            }
            className="w-full rounded-md border-borders"
          />
        </PopUpFilter>
      )}
    />
  );
};
