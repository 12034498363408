import { ApplicationType } from "@prisma/client";
import { NameEnum } from "~/bff/graphql/generated/graphql";
import { ParsedStage } from "~/src/app/company/(dashboard)/pipeline/hooks";

export const MILESTONE_TYPE = {
  SOURCING: "SOURCING",
  APPLICATION_REVIEW: "APPLICATION_REVIEW",
  PRE_SCREEN: "PRE_SCREEN",
  INTERVIEWING: "INTERVIEWING",
  CUSTOM: "CUSTOM",
  OFFER: "OFFER",
  HIRED: "HIRED",
} as const;

export interface Milestone {
  milestoneId: number;
  name: string;
  milestoneType: NameEnum | null;
  stages: ParsedStage[];
}

export interface MatchTojobProps {
  name: string;
  applicationType: ApplicationType;
  seekerId: number;
  uploadedCandidateId: number;
  applicationId: number;
  nextStageId: number;
  jobRequisitionNumber: number;
  jobTitle: string;
  milestoneType: NameEnum;
  alreadyInvitedToJob: boolean;
  recentlyInvitedToAnotherJob: boolean;
}

export interface AdvanceProps {
  name: string;
  applicationType: ApplicationType;
  seekerId: number;
  uploadedCandidateId: number;
  applicationId: number;
  alreadyInvitedToJob: boolean;
  recentlyInvitedToAnotherJob: boolean;
}

export interface RejectProps {
  applicationId: number;
  name: string;
  email: string;
}
