import { useMemo } from "react";
import { useGetScreenStageCollaboratorsQuery } from "~/bff/graphql/generated/graphql";
import { useSearchParamsManager } from "~/scalis-components/team/hooks/use-search-params-manager";
import { DIALOG_QUERY_PARAM } from "~/scalis-components/team/stage-collaborators-dialog";
import {
  AvatarGroup,
  AvatarProps,
  AvatarVariantSizeEnum,
  Icon,
} from "~/scalis-components/core";
import { StageCollaboratorsProps } from "./stage-collaborators.types";
import { BUTTON_TITLE } from "./stage-settings.constants";

export const StageCollaborators = ({ stageId }: StageCollaboratorsProps) => {
  const { updateQueryParam } = useSearchParamsManager();

  const { data } = useGetScreenStageCollaboratorsQuery({
    variables: { id: stageId },
  });

  const avatars = useMemo<AvatarProps[]>(() => {
    return (
      data?.GetTemplateStage?.StageCollaborator?.map(stageCollaborator => {
        const companyUser = stageCollaborator.CompanyUser;
        const scalisUser = companyUser?.UserInvitation?.ScalisUser;
        const fullName = `${scalisUser?.firstName} ${scalisUser?.lastName}`;

        return {
          id: stageCollaborator.id,
          fullName: fullName,
          displayFullName: false,
          src: companyUser?.profileImage ?? "",
        };
      }) ?? []
    );
  }, [data?.GetTemplateStage?.StageCollaborator]);

  const onClick = () => {
    updateQueryParam(DIALOG_QUERY_PARAM, stageId.toString());
  };

  return (
    <>
      {!avatars.length && (
        <Icon
          title={BUTTON_TITLE}
          onClick={onClick}
          icon="fa-regular fa-circle-plus"
        />
      )}

      {!!avatars.length && (
        <button title={BUTTON_TITLE} onClick={onClick}>
          <AvatarGroup
            size={AvatarVariantSizeEnum.Small}
            maxAvatars={4}
            avatars={avatars}
          />
        </button>
      )}
    </>
  );
};
