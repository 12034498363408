import { ApplicationStatusEnum } from "~/bff/graphql/generated/graphql";

export const FIELD_NAME_SEARCH = "search";
export const FIELD_NAME_LOCATION = "location";
export const FIELD_NAME_MATCH_SCORE = "matchScore";
export const FIELD_NAME_EMAIL = "emails";
export const FIELD_NAME_SCREENED_BY = "screenedBy";
export const FIELD_NAME_SOURCE = "source";
export const FIELD_NAME_PHONE = "phone";
export const FIELD_NAME_APPLICATION_STATUS = "applicationStatus";
export const FIELD_NAME_CANDIDATE_TYPE = "candidateType";
export const FIELD_NAME_BOOLEAN_SEARCH = "booleanSearch";
export const FIELD_NAME_MEDALIST_DESIGNATION = "medalistDesignation";
export const FIELD_NAME_SCREENING_QUESTIONS = "screeningQuestions";
export const FIELD_NAME_DEGREE = "degrees";
export const FIELD_NAME_CANDIDATE_TIME_ZONE = "timezones";
export const FIELD_NAME_SKILLS = "skills";
export const FIELD_NAME_COMPANY = "companies";
export const FIELD_NAME_MAJOR = "major";
export const FIELD_NAME_JOB_TITLE = "jobTitles";
export const FIELD_NAME_VETERAN_STATUS = "veteranStatus";
export const FIELD_NAME_WILLING_TO_RELOCATE = "willingToRelocate";
export const FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY = "nonTraditional";
export const FIELD_NAME_SCHOOL = "school";
export const FIELD_NAME_CANDIDATE_PROFILE_ID = "candidateProfileId";
export const FIELD_NAME_YEARS_OF_EXPERIENCE = "yearsOfExperience";
export const FIELD_NAME_LAST_ACTIVITY_OPTION = "lastActivityOption";
export const FIELD_NAME_LAST_ACTIVITY = "lastActivity";

export const FORM_FIELDS = {
  [FIELD_NAME_SEARCH]: {
    label: "Search",
  },
  [FIELD_NAME_LOCATION]: {
    label: "Location",
  },
  [FIELD_NAME_MATCH_SCORE]: {
    label: "Match Score",
  },
  [FIELD_NAME_EMAIL]: {
    label: "Email",
  },
  [FIELD_NAME_SCREENED_BY]: {
    label: "Screened By",
  },
  [FIELD_NAME_SOURCE]: {
    label: "Source",
  },
  [FIELD_NAME_PHONE]: {
    label: "Phone Number",
  },
  [FIELD_NAME_APPLICATION_STATUS]: {
    label: "Status",
    options: [
      { value: ApplicationStatusEnum.Prospect, label: "Prospect" },
      {
        value: ApplicationStatusEnum.InvitedToApply,
        label: "Invited to Apply",
      },
      { value: ApplicationStatusEnum.InviteDeclined, label: "Invite Declined" },
      {
        value: ApplicationStatusEnum.InterviewNotScheduled,
        label: "Interview Not Scheduled",
      },
      {
        value: ApplicationStatusEnum.InterviewRequested,
        label: "Interview Requested",
      },
      {
        value: ApplicationStatusEnum.InterviewScheduled,
        label: "Interview Scheduled",
      },
      {
        value: ApplicationStatusEnum.InterviewCompleted,
        label: "Interview Completed",
      },
      // { value: ApplicationStatusEnum.Hired, label: "Hired" },
      { value: ApplicationStatusEnum.Rejected, label: "Rejected" },
    ],
  },
  [FIELD_NAME_CANDIDATE_TYPE]: {
    label: "Candidate Type",
  },
  [FIELD_NAME_BOOLEAN_SEARCH]: {
    label: "Boolean Search",
  },
  [FIELD_NAME_MEDALIST_DESIGNATION]: {
    label: "Medalist Designation",
  },
  [FIELD_NAME_SCREENING_QUESTIONS]: {
    label: "Screening Questions",
  },
  [FIELD_NAME_DEGREE]: {
    label: "Degree",
    optionsName: "degrees",
  },
  [FIELD_NAME_CANDIDATE_TIME_ZONE]: {
    label: "Candidate Time Zone",
  },
  [FIELD_NAME_SKILLS]: {
    label: "Skills",
    emptyTerm: "skills",
  },
  [FIELD_NAME_COMPANY]: {
    label: "Company",
  },
  [FIELD_NAME_MAJOR]: {
    label: "Major",
  },
  [FIELD_NAME_JOB_TITLE]: {
    label: "Job Title",
  },
  [FIELD_NAME_VETERAN_STATUS]: {
    label: "Veteran Status",
    options: [
      {
        label: "Veterans only",
        value: true,
      },
    ],
  },
  [FIELD_NAME_WILLING_TO_RELOCATE]: {
    label: "Willing to Relocate",
    options: [
      {
        label: "Yes",
        value: true,
      },
    ],
  },
  [FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY]: {
    label: "Non-Traditional Work History",
    options: [
      {
        label: "Yes",
        value: true,
      },
    ],
  },
  [FIELD_NAME_SCHOOL]: {
    label: "School",
  },
  [FIELD_NAME_CANDIDATE_PROFILE_ID]: {
    label: "Candidate Name",
    emptyTerm: "candidates",
  },
  [FIELD_NAME_YEARS_OF_EXPERIENCE]: {
    label: "Years of Experience",
    placeholder: "Ex: 5",
  },
  [FIELD_NAME_LAST_ACTIVITY_OPTION]: {
    label: "Last Activity Date",
    options: [
      {
        label: "Last 7 Days",
        value: "last_7_days",
      },
      {
        label: "Last 30 Days",
        value: "last_30_days",
      },
      {
        label: "This Month",
        value: "this_month",
      },
      {
        label: "Last Month",
        value: "last_month",
      },
      {
        label: "This Quarter",
        value: "this_quarter",
      },
      {
        label: "Last Quarter",
        value: "last_quarter",
      },
      {
        label: "This Year",
        value: "this_year",
      },
      {
        label: "Last Year",
        value: "last_year",
      },
    ],
  },
};
