"use client";

import { useContext } from "react";
import { format, subDays, formatDuration, intervalToDuration } from "date-fns";
import { ApplicationType } from "@prisma/client";

import { EducationLevelEnum } from "~/bff/graphql/generated/graphql";
import { educationLevelMap } from "~/utils/enum-mappers";
import { industries } from "~/utils/industries";
import { ListViewColumn } from "~/scalis-components/core/list-view/list-view.types";
import { MatchScore } from "./components/match-score/match-score";
import { CandidateNameFooter } from "./components/name-footer/candidate-name-footer";
import { CopyableCell } from "./components/copyable-cell";
import { DetailedCandidateContext } from "../../detailed-candidate-view";
import { Status } from "../../status";

const dateRender = (date?: string) => {
  if (!date) return null;
  return format(new Date(date), "EEEE',' LLL d',' u h:maaa");
};

const matchScoreRender = (score?: number) => {
  if (!score) return null;
  return <MatchScore score={Math.trunc(score * 100)} />;
};

const industryRender = (industry?: string) => {
  if (!industry) return null;
  return industries.find(i => i.value === industry)?.label ?? industry;
};

const applicationTypeRender = (applicationType?: ApplicationType) => {
  switch (applicationType) {
    case ApplicationType.STATIC:
      return "Uploaded Profile";
    case ApplicationType.DYNAMIC:
      return "SCALIS Profile";
    default:
      return null;
  }
};

const copyableCellRender = (value: string) => {
  return <CopyableCell value={value} />;
};

const candidateNameRender = (value: string, row?: any) => {
  const { jobId, applyApplicationId, openDetailedCandidateDrawer } = useContext(
    DetailedCandidateContext,
  );

  const handleOpenDetailedCandidateDrawer = () => {
    applyApplicationId(Number(row.id));
    openDetailedCandidateDrawer();
  };

  if (!row || !jobId) return value;

  return (
    <a className="w-full" onClick={handleOpenDetailedCandidateDrawer}>
      {value}
    </a>
  );
};

const statusRender = (status?: string, row?: any) => {
  return (
    <Status
      status={status}
      lastUpdatedStatusAt={row?.original?.statusTimestamp}
    />
  );
};

const educationLevelRender = (educationLevel?: EducationLevelEnum) => {
  if (!educationLevel) return null;
  return educationLevelMap[educationLevel];
};

const tenureRender = (tenure: number) => {
  if (!tenure) return null;

  const tenureDate = subDays(new Date(), tenure);
  const duration = intervalToDuration({ start: tenureDate, end: new Date() });

  const fullFormat = formatDuration(duration);
  const shortFormat = formatDuration(duration, { format: ["years", "months"] });

  return <div title={fullFormat}>{shortFormat}</div>;
};

export const getCandidateListViewColumns = (
  showSeeAllCandidates: boolean,
  allCandidatesCallback: () => void,
) =>
  [
    {
      key: "name",
      value: "Candidate Name",
      meta: {
        isPinned: true,
        header: {
          isDraggable: false,
          canHide: false,
        },
        rows: {
          isDraggable: true,
          contentRender: candidateNameRender,
        },
      },
      footer: (
        <CandidateNameFooter
          allCandidatesCallback={allCandidatesCallback}
          showSeeAllCandidates={showSeeAllCandidates}
        />
      ),
      size: 200,
    },
    {
      key: "status",
      value: "Status",
      meta: {
        rows: {
          isDraggable: true,
          contentRender: statusRender,
        },
      },
      size: 224,
    },
    {
      key: "matchScore",
      value: "Match Score",
      meta: {
        rows: {
          contentRender: matchScoreRender,
        },
      },
      size: 112,
    },
    {
      key: "mostRecentJob",
      value: "Most Recent Job",
      size: 200,
    },
    {
      key: "currentJob",
      value: "Current Job",
      size: 200,
    },
    {
      key: "mostRecentCompany",
      value: "Most Recent Company",
      size: 200,
    },
    {
      key: "currentCompany",
      value: "Current Company",
      size: 200,
    },
    {
      key: "mostRecentIndustry",
      value: "Most Recent Industry",
      size: 200,
      meta: {
        rows: {
          contentRender: industryRender,
        },
      },
    },
    {
      key: "location",
      value: "Location",
      size: 240,
    },
    {
      key: "applicationType",
      value: "Application Type",
      meta: {
        rows: {
          contentRender: applicationTypeRender,
        },
      },
    },
    {
      key: "email",
      value: "Email",
      meta: {
        rows: {
          contentRender: copyableCellRender,
        },
      },
      size: 240,
    },
    {
      key: "education",
      value: "Education",
      size: 280,
    },
    {
      key: "educationLevel",
      value: "Education Level",
      meta: {
        rows: {
          contentRender: educationLevelRender,
        },
      },
    },
    {
      key: "phone",
      value: "Phone Number",
      meta: {
        rows: {
          contentRender: copyableCellRender,
        },
      },
    },
    {
      key: "appliedAt",
      value: "Application Date",
      meta: {
        rows: {
          contentRender: dateRender,
        },
      },
      size: 270,
    },
    {
      key: "averageTenure",
      value: "Average Tenure",
      meta: {
        rows: {
          contentRender: tenureRender,
        },
      },
    },
    {
      key: "shortestTenure",
      value: "Shortest Tenure",
      meta: {
        rows: {
          contentRender: tenureRender,
        },
      },
    },
    {
      key: "longestTenure",
      value: "Longest Tenure",
      meta: {
        rows: {
          contentRender: tenureRender,
        },
      },
    },
    {
      key: "country",
      value: "Country",
    },
  ] as ListViewColumn[];
