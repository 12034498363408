import * as filtersConst from "~/scalis-components/team/contexts/candidate-application-filters-context/candidate-application-filters-context.constants";
import { JobListingFiltersData } from "./use-get-job-listing-filters-data.types";

export const EMPTY_JOB_LISTING_FILTERS_DATA: JobListingFiltersData = {
  [filtersConst.FIELD_NAME_CANDIDATE_PROFILE_ID]: [],
  [filtersConst.FIELD_NAME_COMPANY]: [],
  [filtersConst.FIELD_NAME_DEGREE]: [],
  [filtersConst.FIELD_NAME_EMAIL]: [],
  [filtersConst.FIELD_NAME_JOB_TITLE]: [],
  [filtersConst.FIELD_NAME_MAJOR]: [],
  [filtersConst.FIELD_NAME_SCHOOL]: [],
  [filtersConst.FIELD_NAME_SKILLS]: [],
};
