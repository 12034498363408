import { flatten } from "lodash";
import { Routes } from "~/src/app/_constants/routes";
import {
  getMilestoneTypeProps,
  getTitle,
} from "~/scalis-components/pipeline/list-view/candidate-list-view/utils";
import { MapMilestonesToCollapsableListProps } from "./milestones-list.types";

export const mapMilestonesToCollapsableList = ({
  milestones,
  jobPublicListingId,
  stageCandidates,
  ...props
}: MapMilestonesToCollapsableListProps) => {
  const listArray = milestones.map((milestone, index) => {
    const nextStageId = milestones[index + 1]?.stages[0]?.stageId ?? -1;

    return milestone.stages.map(stage => {
      const milestoneType = milestone.milestoneType as string;

      const { color, rowActionsMenuItems, actionsBarItems } =
        getMilestoneTypeProps({ milestoneType, nextStageId, ...props });

      const title = getTitle(milestone.name, stage.name);

      const viewDetailsRoute = Routes.job_listing.stage({
        jobPublicListingId,
        milestoneId: milestone.milestoneId,
        stageId: stage.stageId,
      });

      const { candidates, count, loading } = stageCandidates[stage.stageId];

      return {
        id: stage.stageId,
        title,
        squareColor: color,
        candidates,
        isLoading: loading,
        showSeeAllCandidates: count > candidates.length,
        listView: {
          tableColor: color,
          rowActionsMenuItems,
          actionsBarItems,
        },
        viewDetailsRoute,
      };
    });
  });

  return flatten(listArray);
};
