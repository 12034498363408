import React from "react";
import { ActionButtonsProps } from "./action-buttons.types";
import { Button } from "~/scalis-components/core/button";

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  actionBarItems,
  candidate,
}) => {
  const filteredActionBarItems = actionBarItems.filter(({ render }) => {
    if (!render.shouldAlwaysRender && !render.renderif(candidate)) {
      return false;
    }

    return true;
  });

  return (
    <>
      {filteredActionBarItems.map(({ id, label, ...props }) => {
        return <Button key={id} text={label} {...props} />;
      })}
    </>
  );
};
