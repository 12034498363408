import { ColumnMeta } from "@tanstack/react-table";

export const Position = {
  Before: "before",
  After: "after",
} as const;

export interface ListViewColumn {
  key: string;
  value: string;
  meta?: ColumnMeta<unknown, unknown>;
  size?: number;
  footer?: React.ReactNode;
}

export interface ActionsMenuItems {
  onClick?: (props: any) => void;
  label: string;
  id: string;
  icon?: string;
}

export type TableColorTypes =
  | "01"
  | "02"
  | "03"
  | "04"
  | "05"
  | "06"
  | "07"
  | "08"
  | "09"
  | "10";

export type Row = { id: string | number };

export interface ListViewProps {
  id: string | number;
  rows: Row[];
  columns: ListViewColumn[];
  tableColor: TableColorTypes;
  rowActionsMenuItems: ActionsMenuItems[][];
  children?: React.ReactNode;
}
