import { cn } from "~/utils/cn";
import { Icon, Avatar } from "~/scalis-components/core";
import { MemberListItemProps } from "./member-list-item.types";

export const MemberListItem = ({
  member,
  onRemove,
  isSelected,
  term = "member",
}: MemberListItemProps) => {
  const removeTitle = `Remove ${term}`;

  return (
    <div
      className={cn(
        "grid items-center gap-2",
        isSelected
          ? "grid-cols-[auto_auto_1fr_minmax(120px,auto)_auto]"
          : "grid-cols-[auto_auto_1fr]",
        member.isInvited && "opacity-70",
      )}
    >
      <Avatar src={member.avatarUrl} fullName={member.name} />
      <div className="flex flex-col">
        <span className="truncate text-sm/6 tracking-tighter text-neutral-primary">
          {member.name}
        </span>
        <span className="truncate text-xs/4 tracking-tight text-neutral-secondary">
          {member.email}
        </span>
      </div>

      {isSelected && (
        <>
          <div /> {/* Empty div to maintain grid layout */}
          {member.isInvited && (
            <div className="text-center text-sm text-gray-500">
              Invite pending
            </div>
          )}
          {onRemove && (
            <Icon
              className="justify-self-end"
              icon="fa-solid fa-xmark cursor-pointer text-icon-neutral-20"
              onClick={() => onRemove(member.id)}
              aria-label={removeTitle}
              title={removeTitle}
            />
          )}
        </>
      )}
    </div>
  );
};
