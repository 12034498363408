import React from "react";

import { ComboBoxProps, Item } from "./combobox.types";
import * as Popover from "@radix-ui/react-popover";
import { Input } from "../../input";

export const ComboBox = <T extends Item>({
  label,
  list,
  handleFilter,
  noResults,
  onSelect,
  placeholder,
  renderItem,
  term,
  renderAfterList,
  ...props
}: ComboBoxProps<T>) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState("");
  const [focused, setFocused] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState<T[]>(list);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setFilteredItems(list);
    }

    setFocused(true);

    setValue(e.target.value);
  };

  React.useEffect(() => {
    setFilteredItems(list);
  }, [list]);

  React.useEffect(() => {
    if (value === "") {
      setFocused(false);
    }

    setFilteredItems(handleFilter(value));
  }, [value]);

  const handleToggleOpen = (open: boolean) => {
    setFocused(open);
    inputRef.current?.focus();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setFocused(false);
    }
  };

  const preventAutoFocus = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Popover.Root open={focused} onOpenChange={handleToggleOpen}>
      <Popover.Trigger className="w-full text-left">
        <Input
          label={label}
          ref={inputRef}
          onChange={handleChange}
          value={value}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder ?? `Search ${term}...`}
          {...props}
        />
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          align={"start"}
          className="z-[150]"
          onOpenAutoFocus={preventAutoFocus}
          onCloseAutoFocus={preventAutoFocus}
          style={{ width: inputRef.current?.getBoundingClientRect().width }}
        >
          <div className="border-px mt-2 flex w-full flex-col gap-1 rounded-xl border border-solid border-neutral-00 bg-white shadow-lg">
            <div className="p-2">
              {filteredItems?.length === 0 && focused && noResults}

              {filteredItems?.map(item => (
                <div
                  onClick={e => {
                    e.preventDefault();
                    setFocused(false);
                    onSelect(item);
                    setValue("");
                    inputRef.current?.focus();
                  }}
                  key={item.id}
                  className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg p-2 transition-all hover:bg-slate-50"
                >
                  {renderItem(item)}
                </div>
              ))}
            </div>

            {renderAfterList &&
              renderAfterList(value, () => {
                setFocused(false);
                setValue("");
                inputRef.current?.focus();
              })}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
