import { tabs } from "./candidate-tab-view.styles";
import {
  VIEW_NAMES,
  VIEW_TYPES,
  View,
  DetailedView,
} from "./candidate-tab-view.types";

import {
  RectangleStackIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { ListBulletIcon } from "@heroicons/react/24/solid";

export const getCandidateViewDetails = ({
  Component,
  type,
}: View): DetailedView => {
  const { triggerIcon } = tabs();

  switch (type) {
    case VIEW_TYPES.CARDS:
      return {
        id: VIEW_TYPES.CARDS,
        viewName: VIEW_NAMES.CARDS,
        Component,
        Icon: () => <RectangleStackIcon className={triggerIcon()} />,
      };
    case VIEW_TYPES.KANBAN:
      return {
        id: VIEW_TYPES.KANBAN,
        viewName: VIEW_NAMES.KANBAN,
        Component,
        Icon: () => <ViewColumnsIcon className={triggerIcon()} />,
      };
    case VIEW_TYPES.LIST:
      return {
        id: VIEW_TYPES.LIST,
        viewName: VIEW_NAMES.LIST,
        Component,
        Icon: () => <ListBulletIcon className={triggerIcon()} />,
      };
    default:
      throw new Error("Invalid view type");
  }
};
