import { tv } from "tailwind-variants";

export const buttonLabelClassName = tv({
  base: "overflow-hidden text-ellipsis whitespace-nowrap",
});

export const buttonEndAdornmentClassName = tv({
  base: "fa-light fa-chevron-down transition-transform",
  variants: {
    open: {
      true: "-rotate-180",
    },
  },
});

export const buttonResetIconClassName = tv({
  base: "fa-light fa-xmark z-50 text-sm text-icon-neutral-20",
});

export const contentClassName = tv({
  base: "text-popover-foreground w-[260px] rounded-xl border border-neutral-00 bg-white shadow-lg outline-none",
});
