import { castArray } from "lodash";
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
} from "~/bff/graphql/generated/graphql";
import { QueryFilters } from "~/scalis-components/team/contexts/filters-context";
import { FormValues } from "./candidate-application-filters-context.schema";
import { CandidateApplicationFilterTab } from "./candidate-application-filters-context.types";

const updateApplicationStatusFilter = (
  filters: QueryFilters,
  status: ApplicationStatusEnum,
) => {
  const curApplicationStatus = filters.applicationStatus
    ? castArray(filters.applicationStatus)
    : [];

  if (!curApplicationStatus.includes(status)) {
    return {
      ...filters,
      applicationStatus: [...curApplicationStatus, status],
    };
  }

  return filters;
};

const applyTabFilters = (
  filters: QueryFilters,
  activeTab?: string,
): QueryFilters => {
  switch (activeTab) {
    case CandidateApplicationFilterTab.ACTIVE_CANDIDATES:
      return {
        ...filters,
        applicationStatusNotIn: [ApplicationStatusEnum.Rejected],
      };
    case CandidateApplicationFilterTab.SCALIS_CANDIDATES:
      return {
        ...filters,
        applicationType: ApplicationTypeEnum.Dynamic,
      };
    case CandidateApplicationFilterTab.UPLOADED:
      return {
        ...filters,
        applicationType: ApplicationTypeEnum.Static,
      };
    case CandidateApplicationFilterTab.INVITED_TO_APPLY: {
      return updateApplicationStatusFilter(
        filters,
        ApplicationStatusEnum.InvitedToApply,
      );
    }
    case CandidateApplicationFilterTab.REJECTED: {
      return updateApplicationStatusFilter(
        filters,
        ApplicationStatusEnum.Rejected,
      );
    }
    default:
      return filters;
  }
};

export const mapRawFiltersToQueryFilters = (
  values: FormValues,
  activeTab?: string,
): QueryFilters => {
  const filters: QueryFilters = {
    matchScore: {
      min: values.matchScore[0],
      max: values.matchScore[1],
    },
    candidateProfileId: values.candidateProfileId.map(Number),
    skills: values.skills,
    lastActivity: values.lastActivity,
    applicationStatus: values.applicationStatus,
  };

  return applyTabFilters(filters, activeTab);
};
