import React from "react";
import { CollapsibleFilterFieldProps } from "./collapsible-filter-field.types";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/v2/Collapsible";
import { Reset } from "../reset";

export const CollapsibleFilterField: React.FC<CollapsibleFilterFieldProps> = ({
  label,
  children,
  onResetField,
  showReset,
  defaultOpen = false,
}) => {
  return (
    <Collapsible
      key={`collapsible-filter-${label}`}
      className="flex flex-col border-t border-solid border-borders-borders"
      defaultOpen={defaultOpen}
    >
      <CollapsibleTrigger className="group flex items-center justify-between gap-2 px-4 py-3">
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-sm/6 font-medium tracking-tighter text-neutral-primary">
            {label}
          </h2>
          {showReset && <Reset onClick={onResetField} />}
        </div>
        <i className="fa-regular fa-chevron-down duration-300 ease-in-out group-data-[state=open]:rotate-180" />
      </CollapsibleTrigger>
      <CollapsibleContent className="flex flex-col px-4 data-[state=open]:pb-3">
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
};
