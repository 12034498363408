import {
  getMilestoneTypeProps,
  getTitle,
} from "~/scalis-components/pipeline/list-view/candidate-list-view/utils";
import { GetMappedMilestoneProps } from "./milestone-detail-list-view.types";

export const getMappedMilestone = ({
  milestone,
  stage,
  stageCandidates,
  ...props
}: GetMappedMilestoneProps) => {
  const milestoneType = milestone?.milestoneType ?? "";

  const { color, rowActionsMenuItems, actionsBarItems } = getMilestoneTypeProps(
    { milestoneType, nextStageId: stage?.nextStageId, ...props },
  );

  const title = getTitle(milestone?.name ?? "", stage?.name ?? "");

  const { candidates, loading } = stageCandidates[stage?.stageId ?? 0];

  return {
    id: Number(stage?.stageId),
    title,
    squareColor: color,
    candidates,
    isLoading: loading,
    listView: {
      tableColor: color,
      rowActionsMenuItems,
      actionsBarItems,
    },
  };
};
