"use client";

import { useState } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { useFormContext } from "react-hook-form";
import { Popover, PopoverTrigger } from "~/components/v2/Popover";
import { Button, ButtonStandaloneKind } from "~/scalis-components/core";
import { PopUpFilterProps } from "./pop-up-filter.types";
import {
  buttonEndAdornmentClassName,
  buttonLabelClassName,
  buttonResetIconClassName,
  contentClassName,
} from "./pop-up-filter.styles";

export const PopUpFilter: React.FC<PopUpFilterProps> = ({
  name,
  children,
  label,
  active,
  count,
  onApplyFilters,
  onReset,
  render,
}) => {
  const [open, setOpen] = useState(false);
  const { resetField } = useFormContext();

  const handleReset = () => {
    if (onReset) return onReset();

    resetField(name);
    onApplyFilters?.(name);
  };

  const onResetIconClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setOpen(false);
    handleReset();
  };

  return (
    <Popover open={open} onOpenChange={() => setOpen(state => !state)}>
      <PopoverTrigger asChild>
        <Button kind={ButtonStandaloneKind.subtle} isStandalone>
          <span className={buttonLabelClassName()}>
            {!!count && `${count}・`}
            {label}
          </span>

          <i aria-hidden className={buttonEndAdornmentClassName({ open })} />

          {active && (
            <i
              aria-hidden
              role="button"
              className={buttonResetIconClassName()}
              onClick={onResetIconClick}
            />
          )}
        </Button>
      </PopoverTrigger>

      <PopoverPrimitive.Content
        className={contentClassName()}
        sideOffset={4}
        align="start"
      >
        {render?.({ setPopupOpenState: setOpen })}
        {children}
      </PopoverPrimitive.Content>
    </Popover>
  );
};
