import {
  FilterContentOption,
  Option,
} from "./use-get-job-listing-filters-data.types";

const getOptionValue = (
  option: FilterContentOption,
  labelAsValue?: boolean,
) => {
  if (labelAsValue) return option.value;
  return option.id?.toString() ?? "";
};

export const mapToOptions = (
  filterContentOptions?: FilterContentOption[],
  options?: { labelAsValue?: boolean },
): Option[] => {
  return (
    filterContentOptions?.map(option => ({
      value: getOptionValue(option, options?.labelAsValue),
      label: option.value,
    })) ?? []
  );
};
